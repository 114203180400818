const initialState = () => ({
	roles: null
})

const state = initialState()

// getters
const getters = {
	roles: state => state.roles
}

// actions
const actions = {
	setRoles({ commit }, roles) {
		return new Promise((resolve, reject) => {
			commit('SET_ROLES', roles)
			resolve()
		})
	},
	reset({ commit }) {
		commit('RESET')
	}
}

const mutations = {

	SET_ROLES(state, value) {
		state.roles = value
	},

	RESET(state) {
		const newState = initialState()
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
