const initialState = () => ({
	user: null
})

const state = initialState()

// getters
const getters = {
	user: state => state.user
}

// actions
const actions = {

	setUser({ commit }, user) {
		return new Promise((resolve, reject) => {
			commit('SET_USER', user)
			resolve()
		})
	},
	reset({ commit }) {
		commit('RESET')
	}

}

// mutations
const mutations = {

	SET_USER(state, user) {
		state.user = user
	},

	RESET(state) {
		const newState = initialState()
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
