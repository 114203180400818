<template>
	<nav class="bg-white">
		<div class="px-4 mx-auto max-w-7xl">
			<div class="flex items-center justify-between h-16">
				<div class="flex items-center">
					<div class="flex-shrink-0">
						<img class="w-8 h-8" src="@/assets/img/logo.svg" alt="Arcadis"/>
					</div>
					<div class="hidden md:block">
						<div class="flex items-baseline ml-10 space-x-4 text-arcadis-gray-800">
							<router-link :to="{name:'dashboard.index'}" class="px-3 py-2 text-base font-semibold rounded-full hover:text-white hover:bg-arcadis-orange-400 focus:outline-none focus:text-white focus:bg-arcadis-orange-400 " exact>{{$t('nav.dashboard')}}</router-link>
							<router-link :to="{name:'projects.index'}" class="px-3 py-2 text-base font-semibold rounded-full hover:text-white hover:bg-arcadis-orange-400 focus:outline-none focus:text-white focus:bg-arcadis-orange-400 ">{{$t('nav.projects')}}</router-link>
							<router-link :to="{name:'users.index'}" class="px-3 py-2 text-base font-semibold rounded-full hover:text-white hover:bg-arcadis-orange-400 focus:text-white focus:bg-arcadis-orange-400 ">{{$t('nav.users')}}</router-link>
							<router-link v-if="$route.name && isAllowed" :to="{name:'workflow_builder.project_templates'}" class="px-3 py-2 text-base font-semibold rounded-full hover:text-white hover:bg-arcadis-orange-400 focus:outline-none focus:text-white focus:bg-arcadis-orange-400" :class="$route.name.includes('workflow_builder')? 'bg-arcadis-orange-500 outline-none text-white':''">{{$t('nav.workflow_builder')}}</router-link>
						</div>
					</div>
				</div>
				<div class="hidden md:block" v-if="loggedIn">
					<div class="flex items-center ml-4 md:ml-6">
						
						<!-- <router-link :to="{name:'notifications.index'}" class="px-3 py-2 text-base font-semibold rounded-full hover:text-white hover:text-arcadis-orange-700 focus:outline-none focus:text-white focus:bg-arcadis-orange-400" exact>
							<font-awesome-icon :icon="['far', 'bell']" />
						</router-link> -->

						<div class="relative ml-2">
							<div>
								<!-- <button @click="userMenuOpen = !userMenuOpen" v-click-outside="closeUserMenu" class="flex items-center max-w-xs text-sm text-white rounded-full focus:outline-none focus:shadow-solid" aria-label="User menu" aria-haspopup="true">
									<img class="w-8 h-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""/>
								</button> -->

								<button @click="userMenuOpen = !userMenuOpen" v-click-outside="closeUserMenu" class="p-1 border-2 border-transparent rounded-full text-arcadis-gray-800 hover:text-arcadis-orange-400 focus:outline-none focus:text-arcadis-gray-800" aria-label="User menu" aria-haspopup="true">
									<font-awesome-icon :icon="['far', 'user']"/>
								</button>
							</div>

							<div v-if="userMenuOpen" class="absolute right-0 w-48 mt-2 origin-top-right rounded-md shadow-lg">
								<div class="py-1 bg-white rounded-md shadow-xs text-arcadis-gray-800" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">

									<router-link :to="{name:'account.profile.show'}" class="block px-4 py-2 text-sm hover:bg-arcadis-orange-400 hover:text-white focus:outline-none focus:text-white focus:bg-arcadis-orange-400" role="menuitem">{{$t('nav.account.profile')}}</router-link>
									<a @click="logout" class="block px-4 py-2 text-sm hover:bg-arcadis-orange-400 hover:text-white focus:outline-none focus:text-white focus:bg-arcadis-orange-400" role="menuitem">{{$t('nav.account.logout')}}</a>
								</div>
							</div>
						</div>

					</div>
				</div>
				<div class="flex -mr-2 md:hidden">
					<button @click="mobileNavOpen = !mobileNavOpen" class="inline-flex items-center justify-center p-2 rounded-md text-arcadis-gray-800 hover:text-white hover:bg-arcadis-orange-400 focus:outline-none focus:bg-arcadis-orange-400 focus:text-white">

						<svg v-if="!mobileNavOpen" class="block w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
						</svg>

						<svg v-else class="block w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
						</svg>
					</button>
				</div>
			</div>
		</div>

		<div v-if="mobileNavOpen" class="md:hidden">
			<div class="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-arcadis-gray-800">
				<router-link :to="{name:'dashboard.index'}" class="block px-3 py-2 text-base font-semibold rounded-md hover:text-white hover:bg-arcadis-orange-400 focus:outline-none focus:text-white focus:bg-arcadis-orange-400 ">{{$t('nav.dashboard')}}</router-link>
				<router-link :to="{name:'projects.index'}" class="block px-3 py-2 text-base font-semibold rounded-md hover:text-white hover:bg-arcadis-orange-400 focus:outline-none focus:text-white focus:bg-arcadis-orange-400">{{$t('nav.projects')}}</router-link>
			</div>
			<div class="pt-4 pb-3 border-t border-gray-700" v-if="loggedIn">
				<div class="flex items-center px-5 space-x-3">
					<div class="flex-shrink-0">
						<font-awesome-icon :icon="['far', 'user']"/>
					</div>
					<div class="space-y-1">
						<div class="text-base leading-none font-base text-arcadis-gray-800">
							{{user.user_name}}
						</div>
						<!-- <div class="text-sm leading-none font-sm text-arcadis-gray-800">
							tom@example.com
						</div> -->
					</div>
				</div>
				<div class="px-2 mt-3 space-y-1 text-arcadis-gray-800">
					<router-link :to="{name:'account.profile.show'}" class="block px-3 py-2 text-base rounded-md hover:bg-arcadis-orange-400 hover:text-white focus:outline-none focus:text-white focus:bg-arcadis-orange-400" role="menuitem">{{$t('nav.account.profile')}}</router-link>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
import { mapGetters } from 'vuex'
import msalInstance from '@/helpers/msal/createMSALClient'

export default {
	name: 'main-navigation',
	props: {
		loggedIn: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			userMenuOpen: false,
			mobileNavOpen: false
		}
	},
	watch: {
		'$route'(to, from) {
			this.userMenuOpen = false
			this.mobileNavOpen = false
		}
	},
	computed: {
		...mapGetters({
			user: 'Auth/user'
		}),
		isAllowed() {
			if (this.user) {
				if (this.user.role.name === 'manager' || this.user.role.name === 'admin' || this.user.role.name === 'editor') {
					return true
				}
			}

			return false
		}
	},
	methods: {
		closeUserMenu() {
			this.userMenuOpen = false
		},
		logout() {
			localStorage.removeItem('bwm-token')

			const currentAccounts = msalInstance.getAllAccounts()
			const currentAccount = currentAccounts[0]

			console.log('user to logout', currentAccount)

			msalInstance.logoutRedirect({
				account: currentAccount
			})

			this.$router.go(this.$router.currentRoute)
		}
	}
}
</script>
<style scoped>
.router-link-exact-active {
	@apply bg-arcadis-orange-500 text-white;
}
</style>
