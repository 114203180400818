<template>
	<div v-if="!asHTML" class="border border-arcadis-gray-500 bg-white">
		<editor-menu-bar :editor="editor" v-slot="{ commands, isActive }" class="px-4 py-2">
			<div class="menubar">

				<button class="mr-4 menubar-button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold" :title="$t('components.wysiwyg.bold')">
					<font-awesome-icon :icon="['far', 'bold']"></font-awesome-icon>
				</button>

				<button class="mr-4 menubar-button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic" :title="$t('components.wysiwyg.italic')">
					<font-awesome-icon :icon="['far', 'italic']"></font-awesome-icon>
				</button>

				<button class="mr-4 menubar-button" :class="{ 'is-active': isActive.strike() }" @click="commands.strike" :title="$t('components.wysiwyg.strike')">
					<font-awesome-icon :icon="['far', 'strikethrough']"></font-awesome-icon>
				</button>

				<button class="mr-4 menubar-button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline" :title="$t('components.wysiwyg.underline')">
					<font-awesome-icon :icon="['far', 'underline']"></font-awesome-icon>
				</button>

				<button class="mr-4 menubar-button" :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list" :title="$t('components.wysiwyg.unorderedList')">
					<font-awesome-icon :icon="['far', 'list-ul']"></font-awesome-icon>
				</button>

				<button class="mr-4 menubar-button" :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list" :title="$t('components.wysiwyg.orderedList')">
					<font-awesome-icon :icon="['far', 'list-ol']"></font-awesome-icon>
				</button>

				<button class="mr-2 menubar-button" @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })" :title="$t('components.wysiwyg.tableAdd')">
					<font-awesome-icon :icon="['far', 'table']"></font-awesome-icon>
				</button>

				<span v-if="isActive.table()"  class="border-l border-arcadis-gray-500">
					<button class="ml-2 mr-4 menubar-button text-arcadis-gray-700" @click="commands.deleteTable" :title="$t('components.wysiwyg.tableRemove')">
						<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
					</button>
					<button class="ml-2 menubar-button text-arcadis-gray-700" @click="commands.addColumnBefore" :title="$t('components.wysiwyg.columnBefore')">
						<font-awesome-icon :icon="['far', 'arrow-to-right']"></font-awesome-icon>
					</button>
					<button class="ml-2 menubar-button text-arcadis-gray-700" @click="commands.addColumnAfter" :title="$t('components.wysiwyg.columnAfter')">
						<font-awesome-icon :icon="['far', 'arrow-to-left']"></font-awesome-icon>
					</button>

					<button class="ml-2 mr-4 menubar-button text-arcadis-gray-700" @click="commands.deleteColumn" :title="$t('components.wysiwyg.columnRemove')">
						<font-awesome-icon :icon="['far', 'minus-square']"></font-awesome-icon>
					</button>

					<button class="ml-2 menubar-button text-arcadis-gray-700" @click="commands.addRowBefore" :title="$t('components.wysiwyg.rowBefore')">
						<font-awesome-icon :icon="['far', 'arrow-to-bottom']"></font-awesome-icon>
					</button>

					<button class="ml-2 menubar-button text-arcadis-gray-700" @click="commands.addRowAfter" :title="$t('components.wysiwyg.rowAfter')">
						<font-awesome-icon :icon="['far', 'arrow-to-top']"></font-awesome-icon>
					</button>

					<button class="ml-2 mr-4 menubar-button text-arcadis-gray-700" @click="commands.deleteRow" :title="$t('components.wysiwyg.rowRemove')">
						<font-awesome-icon :icon="['far', 'minus-circle']"></font-awesome-icon>
					</button>
					<button class="ml-2 menubar-button text-arcadis-gray-700" @click="commands.toggleCellMerge" :title="$t('components.wysiwyg.toggleCellMerge')">
						<font-awesome-icon :icon="['far', 'compress-alt']"></font-awesome-icon>
					</button>
				</span>
			</div>
		</editor-menu-bar>
		<editor-content :editor="editor" class="wysiwyg-content" />
	</div>
	<div v-else>
		<div class="wysiwyg-html" v-html="editor.getHTML()"> </div>
	</div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
	Blockquote,
	CodeBlock,
	HardBreak,
	Heading,
	HorizontalRule,
	OrderedList,
	BulletList,
	ListItem,
	TodoItem,
	TodoList,
	Bold,
	Code,
	Italic,
	Link,
	Strike,
	Underline,
	History,
	Image,
	Table,
	TableHeader,
	TableCell,
	TableRow
} from 'tiptap-extensions'

export default {
	name: 'wysiwyg-area',
	components: {
		EditorContent,
		EditorMenuBar
	},
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		asHTML: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			editor: new Editor({
				extensions: [
					new Blockquote(),
					new BulletList(),
					new CodeBlock(),
					new HardBreak(),
					new Heading({ levels: [1, 2, 3] }),
					new HorizontalRule(),
					new ListItem(),
					new OrderedList(),
					new TodoItem(),
					new TodoList(),
					new Link(),
					new Bold(),
					new Code(),
					new Italic(),
					new Strike(),
					new Underline(),
					new History(),
					new Image(),
					new Table({
						resizable: true
					}),
					new TableHeader(),
					new TableRow(),
					new TableCell()
				],
				autofocus: true,
				content: this.value,
				onUpdate: ({ getJSON, getHTML }) => {
					this.json = getJSON()
					this.html = getHTML()
					this.$emit('input', getJSON())
				}
			}),
			html: '',
			json: ''
		}
	},
	beforeDestroy() {
		// Always destroy your editor instance when it's no longer needed
		this.editor.destroy()
	},
	mounted() {
		this.setFieldFocus()
	},
	methods: {
		clearContent() {
			this.editor.clearContent(true)
			this.editor.focus()
		},
		setContent() {
			this.editor.focus()
		},
		setFieldFocus() {
			this.editor.focus('end')
		}
	}
}
</script>
<style lang="scss">
	.menubar {
		.menubar-button{
			@apply w-8 h-8 border-b border-white;
			&.is-active {
				@apply text-arcadis-orange-700 border-b border-arcadis-gray-500;
			}
		}
	}

	.wysiwyg-content {
		@apply p-4;
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
		width: 0;
    	min-width: 100%;

		ul, ol{
			@apply list-outside pl-4;
		}
		ul {
			@apply list-disc;
		}
		ol {
			@apply list-decimal;
		}
		li > p,
		li > ol,
		li > ul {
			margin: 0;
		}
		a {
			color: inherit;
		}

		.ProseMirror{
			min-height: 120px;
			table {
				border-collapse: collapse;
				table-layout: fixed;
				width: 100%;
				margin: 0;
				overflow: hidden;
				font-size: 9.0pt;
				color: #0C0808;

				th {
					font-weight: bold;
					text-align: left;
					background-color: #f1f3f5;
				}
				tr:first-child td {
					color: #E4610F;
					width: 105pt;
					font-weight: bold;
					border-bottom: solid #E4610F 1px;
					padding: 1.4pt 0cm 1.4pt 0cm;
					padding: 3px 5px;
				}
				tr:last-child td {
					border-bottom: solid #E4610F 1px;
				}
				td {
					border-bottom: solid #0C0808 1px;
					border-top: dotted #0C0808 1px;
					border-left: dotted #0C0808 1px;
					border-right: dotted #0C0808 1px;
				}
				td, th {
					padding: 1.4pt 0cm 1.4pt 0cm;
					min-width: 1em;
					padding: 3px 5px;
					vertical-align: top;
					box-sizing: border-box;
					position: relative;
					> * {
						margin-bottom: 0;
					}
				}

				.selectedCell:after {
					z-index: 2;
					position: absolute;
					content: "";
					left: 0; right: 0; top: 0; bottom: 0;
					background: rgba(200, 200, 255, 0.4);
					pointer-events: none;
				}

				.column-resize-handle {
					position: absolute;
					right: -2px; top: 0; bottom: 0;
					width: 4px;
					z-index: 20;
					background-color: #adf;
					pointer-events: none;
				}
			}

			.tableWrapper {
				margin: 1em 0;
				overflow-x: auto;
			}

			.resize-cursor {
				cursor: ew-resize;
				cursor: col-resize;
			}

		}

	}
	.wysiwyg-html {
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;

		p:empty::after {
			content: "\00A0";
		}

		ul, ol{
			@apply list-outside pl-4;
		}
		ul {
			@apply list-disc;
		}
		ol {
			@apply list-decimal;
		}
		li > p,
		li > ol,
		li > ul {
			margin: 0;
		}
		a {
			color: inherit;
		}
		.ProseMirror{
			min-height: 120px;
			font-size: 11.0pt;
			font-family: "Arial",sans-serif;
		}
		table {
			border-collapse: collapse;
			table-layout: fixed;
			width: 100%;
			overflow:auto; 
			margin: 0;
			overflow: hidden;
			font-size: 9.0pt;
			color: #0C0808;

			th {
				font-weight: bold;
				text-align: left;
				background-color: #f1f3f5;
			}
			tr:first-child td {
				color: #E4610F;
				width: 105pt;
				font-weight: bold;
				border-bottom: solid #E4610F 1px;
				padding: 1.4pt 0cm 1.4pt 0cm;
				padding: 3px 5px;
			}
			tr:last-child td {
				border-bottom: solid #E4610F 1px;
			}
			td {
				border-bottom: solid #0C0808 1px;
				border-top: dotted #0C0808 1px;
				border-left: dotted #0C0808 1px;
				border-right: dotted #0C0808 1px;
			}
			td, th {
				padding: 1.4pt 0cm 1.4pt 0cm;
				min-width: 1em;
				padding: 3px 5px;
				vertical-align: top;
				box-sizing: border-box;
				position: relative;
				> * {
					margin-bottom: 0;
				}
			}

			.selectedCell:after {
				z-index: 2;
				position: absolute;
				content: "";
				left: 0; right: 0; top: 0; bottom: 0;
				background: rgba(200, 200, 255, 0.4);
				pointer-events: none;
			}

			.column-resize-handle {
				position: absolute;
				right: -2px; top: 0; bottom: 0;
				width: 4px;
				z-index: 20;
				background-color: #adf;
				pointer-events: none;
			}
		}

		.tableWrapper {
			margin: 1em 0;
			overflow-x: auto;
		}

		.resize-cursor {
			cursor: ew-resize;
			cursor: col-resize;
		}

	}
</style>
