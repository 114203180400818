<template>
	<div :class="{ 'mb-4': isOpen }">
		<div class="flex items-center justify-between px-4 py-4 bg-white rounded-md cursor-pointer scroll-to-me" :class="[{'shadow-2xl' : isOpen}, containerClasses]" @click="toggle">
			<div class="flex items-center">
				<div class="flex items-center justify-center w-6 h-6 mr-2">
					<status-toggler :status="currentStatus" @changeStatus="emitStatus"></status-toggler>
				</div>

				<template v-if="$slots.header">
					<slot name="header"></slot>
				</template>
				<template v-else>
					<span class="text-2xl font-semibold leading-tight" :class="titleClasses">{{title}}</span>
				</template>
			</div>
			<div>
				<div class="flex items-center justify-center w-6 h-6 bg-arcadis-gray-100">
					<font-awesome-icon class="transition-transform duration-200 ease-out transform rotate-0" :class="{'rotate-180':isOpen}" :icon="['far', 'chevron-down']" fixed-width/>
				</div>
			</div>
		</div>
		<div :class="{'overflow-hidden': !isOpen}">
			<transition
				enter-active-class="transition duration-200 ease-out transform"
				enter-class="-translate-y-4 opacity-0"
				enter-to-class="translate-y-0 opacity-100"
				leave-active-class="transition duration-200 ease-in transform"
				leave-class="translate-y-0 opacity-100"
				leave-to-class="-translate-y-4 opacity-0"
				mode="out-in">
				<div class="px-4 py-2 mx-2 mt-1 bg-white" :class="contentClasses" v-if="isOpen">
					<slot></slot>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import StatusToggler from '@/components/StatusToggler'
export default {
	name: 'CollapseItem',
	components: {
		StatusToggler
	},
	props: {
		title: {
			type: String,
			default: 'collapse title'
		},
		containerClasses: {
			type: String,
			default: ''
		},
		contentClasses: {
			type: String,
			default: ''
		},
		titleClasses: {
			type: String,
			default: ''
		},
		scrollInView: {
			type: Boolean,
			default: true
		},
		open: {
			type: Boolean,
			default: false
		},
		selectedTitle: {
			type: String,
			default: ''
		},
		selected: {
			default: false
		},
		status: {
			default: 'todo'
		}
	},
	watch: {
		open() {
			this.isOpen = this.open
		},
		selected() {
			this.isSelected = this.selected
		},
		status() {
			switch (this.status) {
				case 0:
					this.currentStatus = 'todo'
					break
				case 1:
					this.currentStatus = 'doing'
					break
				case 2:
					this.currentStatus = 'done'
					break
				case 3:
					this.currentStatus = 'blocking'
					break
			}
		}
	},
	data() {
		return {
			isOpen: this.open,
			isSelected: this.selected,
			currentStatus: 'todo'
		}
	},
	mounted() {
		switch (this.status) {
			case 0:
				this.currentStatus = 'todo'
				break
			case 1:
				this.currentStatus = 'doing'
				break
			case 2:
				this.currentStatus = 'done'
				break
			case 3:
				this.currentStatus = 'blocking'
				break
		}
	},
	methods: {
		toggle() {
			this.isOpen = !this.isOpen
			this.$emit('toggled', this.isOpen)

			if (this.isOpen && this.scrollInView) {
				this.scrollToElement()
			}
		},
		scrollToElement() {
			const el = this.$el.getElementsByClassName('scroll-to-me')[0]

			if (el) {
				this.$nextTick(() => {
					el.scrollIntoView({ behavior: 'smooth', block: 'start' })
				})
			}
		},
		emitStatus(status) {
			this.$emit('updateStatus', status)
		}
	}
}
</script>
