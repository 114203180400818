<template>
	<div id="app" :class="layout">
		<component :is="layout" :classes="cssClasses">
			<transition
					enter-active-class="transition duration-100 ease-out transform"
					enter-class="opacity-0"
					enter-to-class="opacity-100"
					leave-active-class="transition duration-75 ease-in transform"
					leave-class="opacity-100"
					leave-to-class="opacity-0"
					mode="out-in">
				<router-view ref="routerView"></router-view>
			</transition>
		</component>
	</div>
</template>

<script>

import DefaultLayout from '@/views/layouts/Default.vue'
import NoUserLayout from '@/views/layouts/NoUser.vue'
// import { mapGetters } from 'vuex'

const defaultLayout = 'default'

export default {
	components: {
		DefaultLayout,
		NoUserLayout
	},
	computed: {
		layout() {
			return (this.$route.meta.layout || defaultLayout) + '-layout'
		},
		cssClasses() {
			return (this.$route.meta.cssClasses || '')
		}

	},
	watch: {
		'$route'(to, from) {
			this.$nextTick(() => {
				// Change document title
				document.title = process.env.VUE_APP_TITLE + ' ' + (to.meta.title || '')
	
				// // use timeout for transition time
				// setTimeout(() => {
				// 	// Get component's "routeFocusTarget" ref.
				// 	// If not existent, use router view container
				// 	if (!this.$refs.routerView) {
				// 		return
				// 	}
				// 	const focusTarget = (this.$refs.routerView.$refs.componentFocusTarget !== undefined) ? this.$refs.routerView.$refs.componentFocusTarget : this.$refs.routerView.$el
				// 	// Make focustarget programmatically focussable
				// 	focusTarget.setAttribute('tabindex', '-1')

				// 	// Focus element
				// 	focusTarget.focus()

				// 	// Remove tabindex from focustarget.
				// 	// Reason: https://axesslab.com/skip-links/#update-3-a-comment-from-gov-uk
				// 	focusTarget.removeAttribute('tabindex')
				// }, 180)
			})
		}
	},
	created() {
		// nothing defined here (when this.$route.path is other than "/")
		// console.log(this.$route, this.$route.meta.layout);
	},
	updated() {
		// something defined here whatever the this.$route.path
		// console.log(this.$route, this.$route.meta.layout);
	},
	mounted() {
		//
	},
	methods: {
		styleChangedCallback() {
			//
		}
	}
}

</script>
<style >

</style>
