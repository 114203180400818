<template>
	<div class="relative flex items-center justify-center w-6 h-6 mr-2">
		<div v-if="showOptions" v-click-outside="closeOptions" class="absolute left-0 flex flex-wrap w-24 mb-16 z-1">

			<button class="flex items-center justify-center w-4 h-4 mr-1 overflow-hidden border border-blue-800 border-solid rounded-full" @click.stop="clickedOption('todo')">
				<div class="w-3 h-3 bg-white rounded-full"></div>
			</button>
			<button class="flex items-center justify-center w-4 h-4 mr-1 overflow-hidden border border-blue-800 border-solid rounded-full" @click.stop="clickedOption('doing')">
				<div class="w-3 h-3 bg-blue-800 rounded-full"></div>
			</button>
			<button class="flex items-center justify-center w-4 h-4 mr-1 overflow-hidden border border-blue-800 border-solid rounded-full" @click.stop="clickedOption('done')">
				<div class="w-3 h-3 rounded-full bg-arcadis-green">
					<font-awesome-icon class="text-white" size="xs" :icon="['far', 'check']"></font-awesome-icon>
				</div>
			</button>
			<button class="flex items-center justify-center w-4 h-4 mr-1 overflow-hidden border border-blue-800 border-solid rounded-full" @click.stop="clickedOption('blocking')">
				<div class="w-3 h-3 rounded-full bg-arcadis-red"></div>
			</button>
		</div>
		<button class="flex items-center justify-center w-6 h-6 overflow-hidden border border-blue-800 border-solid rounded-full" @click.stop="openOptions">
			<div v-if="status == 'doing'" class="w-5 h-5 bg-blue-800 rounded-full"></div>
			<div v-if="status == 'done'" class="w-5 h-5 rounded-full bg-arcadis-green">
				<font-awesome-icon class="text-white" :icon="['far', 'check']" size="md"></font-awesome-icon>
			</div>
			<div v-if="status == 'blocking'" class="w-5 h-5 rounded-full bg-arcadis-red">
				<font-awesome-icon class="text-white" :icon="['far', 'exclamation']" size="xs"></font-awesome-icon>
			</div>
		</button>
	</div>
</template>

<script>
export default {
	name: 'StatusToggler',
	props: {
		status: {
			type: String,
			default: 'todo'
		}
	},
	watch: {
	},
	data() {
		return {
			showOptions: false
		}
	},
	methods: {
		openOptions() {
			this.showOptions = true
		},
		closeOptions() {
			this.showOptions = false
		},
		clickedOption(option) {
			this.$emit('changeStatus', option)
			this.closeOptions()
		}
	}
}
</script>
