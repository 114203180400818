const initialState = () => ({
	templates: [],
	baseTemplates: [],
	status: false
})

const state = initialState()

// getters
const getters = {
	templates: state => state.templates,
	baseTemplates: state => state.baseTemplates
}

// actions
const actions = {
	storeTemplates({ commit }, templates) {
		return new Promise((resolve, reject) => {
			commit('UPDATE_STATUS', false)
			commit('CLEAR_TEMPLATES')
			commit('ADD_TEMPLATES', templates)
			commit('UPDATE_STATUS', true)
			resolve()
		})
	},

	storeBaseTemplates({ commit }, baseTemplates) {
		return new Promise((resolve, reject) => {
			commit('UPDATE_STATUS', false)
			commit('CLEAR_BASETEMPLATES')
			commit('ADD_BASETEMPLATES', baseTemplates)
			commit('UPDATE_STATUS', true)
			resolve()
		})
	},

	invalidateTemplates({ commit }) {
		commit('CLEAR_TEMPLATES')
	},

	reset({ commit }) {
		commit('RESET')
	}
}

const mutations = {

	ADD_TEMPLATES(state, data) {
		state.templates = data
	},

	ADD_BASETEMPLATES(state, data) {
		state.baseTemplates = data
	},

	CLEAR_TEMPLATES(state) {
		state.templates = []
		state.baseTemplates = []
	},

	CLEAR_BASETEMPLATES(state) {
		state.baseTemplates = []
	},

	UPDATE_STATUS(state, status) {
		state.status = status
	},

	RESET(state) {
		const newState = initialState()
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
