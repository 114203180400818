import * as components from './components'

import { merge } from './utils/helpers'
import config, { setOptions, setVueInstance } from './utils/config'
import { use, registerComponentProgrammatic } from './utils/plugins'

import ConfigComponent from './utils/ConfigComponent'

const Alledaags = {
	install(Vue, options = {}) {
		setVueInstance(Vue)
		// Options
		setOptions(merge(config, options, true))
		// Components
		for (const componentKey in components) {
			Vue.use(components[componentKey])
		}
		// Config component
		registerComponentProgrammatic(Vue, 'config', ConfigComponent)
	}
}

use(Alledaags)

export default Alledaags

// export all components as vue plugin
export * from './components'
// export programmatic component
export { DialogProgrammatic } from './components/dialog'
export { default as ConfigProgrammatic } from './utils/ConfigComponent'

// export helpers
export * from './utils/helpers'
