import Vue from 'vue'
const initialState = () => ({
	currentSubproject: null,
	currentSubprojectTerraID: null
})

const state = initialState()

// getters
const getters = {
	currentSubproject: state => state.currentSubproject,
	currentSubprojectTerraID: state => state.currentSubprojectTerraID
}

// actions
const actions = {
	setCurrentSubproject({ commit }, project) {
		return new Promise((resolve, reject) => {
			commit('RESET')
			if (project.workscopes) {
				project.workscopes.forEach(workscope => {
					workscope.meta = {
						created_at: workscope.created_at,
						updated_at: workscope.updated_at,
						created_by: workscope.created_by,
						updated_by: workscope.updated_by
					}
				})
			}

			commit('SET_CURRENT_SUBPROJECT', project)
			resolve()
		})
	},
	setCurrentSubprojectTerraID({ commit }, id) {
		return new Promise((resolve, reject) => {
			commit('SET_CURRENT_SUBPROJECTTERRAID', id)
			resolve()
		})
	},
	updateCurrentSubproject({ commit }, data) {
		return new Promise((resolve, reject) => {
			commit('UPDATE_CURRENT_SUBPROJECT', data)
			resolve()
		})
	},
	updateWorkscope({ commit }, data) {
		return new Promise((resolve, reject) => {
			const workscope = data
			workscope.meta = {
				created_at: data.created_at,
				updated_at: data.updated_at,
				created_by: data.created_by,
				updated_by: data.updated_by
			}

			commit('UPDATE_WORKSCOPE', workscope)
			resolve()
		})
	},
	reset({ commit }) {
		commit('RESET')
	}

}

const mutations = {
	SET_CURRENT_SUBPROJECT(state, value) {
		state.currentSubproject = value
	},
	SET_CURRENT_SUBPROJECTTERRAID(state, value) {
		state.currentSubprojectTerraID = value
	},
	UPDATE_CURRENT_SUBPROJECT(state, value) {
		state.currentSubproject = Object.assign({}, state.currentSubproject, value)
	},

	UPDATE_WORKSCOPE(state, value) {
		Vue.set(state.currentSubproject.workscopes, value.type, value)
	},

	RESET(state) {
		const newState = initialState()
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
