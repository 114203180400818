/* eslint-disable */
import Vue from 'vue'
Vue.mixin({
	methods: {
		e7Id () {
			var lut = []; for (var i=0; i<256; i++) { lut[i] = (i<16?'0':'')+(i).toString(16); }
			var d0 = Math.random()*0xffffffff|0;
			var d1 = Math.random()*0xffffffff|0;
			var d2 = Math.random()*0xffffffff|0;
			var d3 = Math.random()*0xffffffff|0;
			return lut[d0&0xff]+lut[d0>>8&0xff]+lut[d0>>16&0xff]+lut[d0>>24&0xff]+'-'+
			lut[d1&0xff]+lut[d1>>8&0xff]+'-'+lut[d1>>16&0x0f|0x40]+lut[d1>>24&0xff]+'-'+
			lut[d2&0x3f|0x80]+lut[d2>>8&0xff]+'-'+lut[d2>>16&0xff]+lut[d2>>24&0xff]+
			lut[d3&0xff]+lut[d3>>8&0xff]+lut[d3>>16&0xff]+lut[d3>>24&0xff];
		},
		formatDate (date) {
			if (!date) return ''
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;

			return [year, month, day].join('-');
		},
		isInViewport(el) {
			const rect = el.getBoundingClientRect()
			return (
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		
			)
		},
		isOutOfViewport(el) {
			// Get element's bounding
			var bounding = el.getBoundingClientRect()
		
			// Check if it's out of the viewport on each side
			var out = {};
			out.top = bounding.top < 0
			out.left = bounding.left < 0
			out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)
			out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth)
			out.any = out.top || out.left || out.bottom || out.right
			out.all = out.top && out.left && out.bottom && out.right
		
			return out;
		}
	}
})
