<template>
	<div class="flex form-check-box">
		<label :class="[{ 'checked' : value}, position]">
			<div class="ml-1">{{ label }}</div>
			<input type="checkbox" :checked="value" @change="$emit('input', $event.target.checked)"/>
		</label>
	</div>
</template>

<script>
export default {
	name: 'form-check-box',
	props: {
		value: { type: Boolean, default: false },
		label: { type: String, default: null },
		position: { type: String, default: null }
	}
}
</script>

<style lang="scss">
	$check-background-color-on: theme('colors.arcadis.orange-500');
	$check-background-color-off: theme('colors.arcadis.gray-500');

	.form-check-box {

		input[type="checkbox"] {
			position: absolute;
			top: 0;
			left: -9999px;
			visibility: hidden;
		}

		label.checked {

			&:after {
				transform: scale(1);
				opacity: 1;
			}

			&:before {
				border-color: $check-background-color-on;
			}
		}

		label:hover {
			&:before {
				border-color: $check-background-color-on
			}
		}

		label {
			cursor: pointer;
			position: relative;
			padding: 0 0 0 24px;
			font-size: 14px;

			&.right {
				padding: 0 24px 0 0;
				&:before, &:after {
					right: 0;
					left: unset;
				}
			}

			&:before, &:after {
				content: '';
				position: absolute;
				border-radius: 0;
			}
			&:before {
				left: 0;
				// bottom: 0;
				margin: 3px 0 0;
				width: 18px;
				height: 18px;
				background: white;
				border: 2px solid $check-background-color-off;
				border-radius: 4px;
			}
			&:after {
				left: 0;
				top: 0;
				width: 10px;
				height: 10px;
				margin: 7px 4px 0;
				opacity: 0;
				background: $check-background-color-on;
				transform: scale(0.5);
				transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
				border-radius: 2px;
			}
		}
	}

</style>
