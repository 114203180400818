const initialState = () => ({
	users: [],
	status: false
})

const state = initialState()

// getters
const getters = {
	users: state => state.users
}

// actions
const actions = {
	storeUsers({ commit }, users) {
		return new Promise((resolve, reject) => {
			commit('UPDATE_STATUS', false)
			commit('CLEAR_USERS')
			commit('ADD_USERS', users)
			commit('UPDATE_STATUS', true)
			resolve()
		})
	},

	invalidateUsers({ commit }) {
		commit('CLEAR_USERS')
	},

	reset({ commit }) {
		commit('RESET')
	}

}

const mutations = {
	ADD_USERS(state, data) {
		state.users = data
	},

	CLEAR_USERS(state) {
		state.users = []
	},

	UPDATE_STATUS(state, status) {
		state.status = status
	},

	RESET(state) {
		const newState = initialState()
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
