<template>
	<div class="min-h-screen bg-arcadis-gray-100">
		<!-- <main-navigation :loggedIn=false /> -->
		<transition
					enter-active-class="transition duration-100 ease-out transform"
					enter-class="scale-95 opacity-0"
					enter-to-class="scale-100 opacity-100"
					leave-active-class="transition duration-75 ease-in transform"
					leave-class="scale-100 opacity-100"
					leave-to-class="scale-95 opacity-0"
					mode="out-in">
			<div v-if="!busy && !userLoading">
				<slot />
			</div>
		</transition>
	</div>
</template>

<script>
import MainNavigation from './partials/MainNavigation'
export default {
	name: 'nouser-layout',
	components: {
		MainNavigation
	},
	data() {
		return {
			busy: false
		}
	},
	computed: {
		userLoading() {
			return false
			// return this.isLoggedIn && this.user == null
		}
	},
	methods: {
		logOut() {
			console.log('logging out')
			// this.busy = true;
		},
		logOutFinished() {
			console.log('logging out DONE')
			// this.busy = false;
		}

	}
}
</script>
