<template>
	<div>
		<label class="inline-flex items-center py-2 rounded-md cursor-pointer">
			<span class="px-4 py-2 rounded-l-md w-16 text-center border-r border-arcadis-orange-300"
				:class="[(localValue === true) ? 'bg-arcadis-orange-400 text-white' : 'bg-gray-300 hover:bg-arcadis-orange-300 hover:text-white']"
				@click="clickedYes">
				{{ $t('general.yes') }}
			</span>
			<span class="px-4 py-2 rounded-r-md w-16 text-center"
				:class="[(localValue === false) ? 'bg-arcadis-orange-400 text-white' : 'bg-gray-300 hover:bg-arcadis-orange-300 hover:text-white']"
				@click="clickedNo">
				{{ $t('general.no') }}
			</span>
		</label>
	</div>
</template>

<script>
export default {
	name: 'form-button-switch',
	props: {
		value: { type: Boolean }
	},
	data() {
		return {
			localValue: null
		}
	},
	created() {
		this.localValue = this.value
	},
	methods: {
		clickedYes() {
			this.localValue = true
			this.$emit('input', this.localValue)
		},
		clickedNo() {
			this.localValue = false
			this.$emit('input', this.localValue)
		}
	}
}
</script>
