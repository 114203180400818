<template>
	<div class="dropdown-search relative z-1" v-click-outside="clickedOutside">
		<span v-if="selected" class="absolute mt-1 ml-1 py-1 px-2 rounded-xs mr-4 bg-arcadis-gray-100"> {{ selected }} </span>
		<input class="block w-full mt-1 form-select"
			v-model="searchQuery"
			:readonly="selected"
			:placeholder="computedPlaceholder"
			@focus="focusedOnInput"
			@keyup="keyUpHandler"/>
		<font-awesome-icon v-if="selected" class="absolute right-0 top-0 text-gray-600 cursor-pointer mr-10" style="margin-top: 0.8rem;" @click="deselect" :icon="['far', 'times']" />
		<div class="absolute top-1/2 z-10 w-full border shadow-50 bg-white overflow-auto" style="max-height:400px;" v-if="isVisible">
			<div class="cursor-pointer hover:bg-arcadis-orange-400 hover:text-white px-4 py-1" v-for="item in data" :key="item.id" @click="selectItem(item)">
				<p>{{ item.name }}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'dropdown-search',
	props: {
		list: { 
			type: Array, 
			default() {
				return []
			}
		},
		placeholder: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			searchQuery: '',
			isVisible: false,
			selected: null
		}
	},
	computed: {
		computedPlaceholder() {
			if(this.selected) {
				return ''
			}
			return this.placeholder
		}
	},
	mounted() {
		this.data = this.list
	},
	methods: {
		toggle() {
			this.isVisible = !this.isVisible
		},
		keyUpHandler() {
			this.data = this.list
			if(this.searchQuery > '') {
				this.data = this.data.filter((item)=> {
					let upperItem = item.name.toUpperCase()
					let upperSearch = this.searchQuery.toUpperCase()
					return upperItem.includes(upperSearch)
				})
			}
		},
		selectItem(item) {
			this.$emit('select', item)
			this.selected = item.name
			this.searchQuery = ''
			this.isVisible = false
		},
		deselect() {
			this.$emit('deselect')
			this.searchQuery = ''
			this.data = this.list
			this.selected = null
		},
		focusedOnInput() {
			if(!this.selected) {
				this.isVisible = true
			}
		},
		clickedOutside() {
			this.isVisible = false
		}
	}
}
</script>
<style lang="scss" scoped>
</style>
