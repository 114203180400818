let config = {
	defaultContainerElement: null,
	defaultDialogConfirmText: null,
	defaultDialogCancelText: null,
	defaultModalCanCancel: ['escape', 'x', 'outside', 'button'],
	defaultTrapFocus: true,
	defaultAutoFocus: true
}

export { config as default }

export const setOptions = (options) => { config = options }

export const setVueInstance = (Vue) => { VueInstance = Vue }

export let VueInstance
