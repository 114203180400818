<template>
	<div class="flex items-center">
		<label class="toggle-control">
			<input type="checkbox" id="toggle" :checked="value"  @change="$emit('input', $event.target.checked)">
			<span class="flex items-center control">
				<span class="text-sm text-gray-700 status-label">{{value ? onLabel : offLabel }}</span>
			</span>
		</label>
		<label v-if="label" for="toggle" class="ml-2">{{label}}</label>
	</div>

</template>

<script>
export default {
	name: 'form-toggle-switch',
	props: {
		value: { type: Boolean, default: false },
		onLabel: { type: String, default: 'on' },
		offLabel: { type: String, default: 'off' },
		label: { type: String, default: null }
	}
}
</script>

<style lang="scss">
	$toggle-background-color-on: theme('colors.arcadis.orange-500');
	$toggle-background-color-off: theme('colors.arcadis.gray-500');
	$toggle-control-color: #F5F5F5;
	$toggle-width: 75px;
	$toggle-height: 26px;
	$toggle-gutter: 2px;
	$toggle-radius: 50%;
	$toggle-control-speed: .15s;
	$toggle-control-ease: ease-in;

	$toggle-radius: calc($toggle-height / 2);
	$toggle-control-size: $toggle-height - ($toggle-gutter * 2);

	.toggle-control {
		display: block;
		position: relative;
		padding-left: $toggle-width;
		cursor: pointer;
		font-size: 22px;
		user-select: none;
		height:26px;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}

		input:checked ~ .control {
			background-color: $toggle-background-color-on;

			&:after {
				left: $toggle-width - $toggle-control-size - $toggle-gutter;
			}

			.status-label{
				text-align: right;
				padding-left: 0 ;
				padding-right: $toggle-height + $toggle-gutter;
			}

		}

		.control {
			position: absolute;
			top: 0;
			left: 0;
			height: $toggle-height;
			width: $toggle-width;
			border-radius: $toggle-radius;
			overflow: hidden;
			background-color: $toggle-background-color-off;
			transition: background-color $toggle-control-speed $toggle-control-ease;

			.status-label{
				display: block;
				padding-left: $toggle-height + $toggle-gutter;
				padding-right: 0;
				text-align: left;
				width: $toggle-width;
				transition: text-align $toggle-control-speed $toggle-control-ease;
			}

			&:after {
				content: "";
				position: absolute;
				left: $toggle-gutter;
				top: $toggle-gutter;
				width: $toggle-control-size;
				height: $toggle-control-size;
				border-radius: $toggle-radius;
				background: $toggle-control-color;
				box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
				transition: left $toggle-control-speed $toggle-control-ease;
			}
		}
	}

</style>
