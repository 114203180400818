/* global axios */
import Vue from 'vue'
import i18n from '@/i18n'
import { SessionManager } from './sessionmanager'

axios.interceptors.response.use(undefined, function (error) {
	// console.log('error.response', error)

	if (error.response) {
		// console.log('error.response', error.response)
		if (error.response.status === 404 || error.response.status === 409 || error.response.status === 422) {
			// Vue.$toast(i18n.t('messages.404'))
			return Promise.reject(error)
		} else {
			Vue.$toast.error(i18n.t('messages.connection_problem'))
		}
	} else {
		Vue.$toast.error(i18n.t('messages.connection_problem'))
	}

	return Promise.reject(error)
})

window.axios.interceptors.request.use(
	config => {
		const accessToken = SessionManager.getAccessToken()
		config.headers = {
			Authorization: `Bearer ${accessToken}`,
			Accept: 'application/json',
			'Content-Type': 'application/json'
		}
		return config
	},
	error => {
		Promise.reject(error)
	})

// Response interceptor for API calls
window.axios.interceptors.response.use((response) => {
	return response
}, async function (error) {
	const originalRequest = error.config
	console.log('auth error', error)
	if (!error.response) {
		return Promise.reject(error)
	}
	if (error.response.status === 403 && !originalRequest._retry) {
		originalRequest._retry = true
		// const access_token = await refreshAccessToken()
		// window.axios.defaults.headers.common.Authorization = 'Bearer ' + access_token
		// return window.axios(originalRequest)
	}
	return Promise.reject(error)
})
