const initialState = () => ({
	countries: null,
	arcadisTypes: null,
	clientTypes: null,
	currentProject: null,
	projects: [],
	leadProjects: [],
	status: false
})

const state = initialState()

// getters
const getters = {
	countries: state => state.countries,
	arcadisTypes: state => state.arcadisTypes,
	clientTypes: state => state.clientTypes,
	currentProject: state => state.currentProject,
	projects: state => state.projects,
	leadProjects: state => state.leadProjects
}

// actions
const actions = {

	setCountries({ commit }, countries) {
		return new Promise((resolve, reject) => {
			commit('SET_COUNTRIES', countries)
			resolve()
		})
	},
	setArcadisTypes({ commit }, arcadisTypes) {
		return new Promise((resolve, reject) => {
			commit('SET_ARCADIS_TYPES', arcadisTypes)
			resolve()
		})
	},
	setClientTypes({ commit }, clientTypes) {
		return new Promise((resolve, reject) => {
			commit('SET_CLIENT_TYPES', clientTypes)
			resolve()
		})
	},

	setCurrentProject({ commit }, project) {
		return new Promise((resolve, reject) => {
			commit('SET_CURRENT_PROJECT', project)
			resolve()
		})
	},
	updateCurrentProject({ commit }, data) {
		return new Promise((resolve, reject) => {
			commit('UPDATE_CURRENT_PROJECT', data)
			resolve()
		})
	},

	storeProjects({ commit }, projects) {
		return new Promise((resolve, reject) => {
			commit('UPDATE_STATUS', false)
			commit('CLEAR_PROJECTS')
			commit('ADD_PROJECTS', projects)
			commit('UPDATE_STATUS', true)
			resolve()
		})
	},

	storeLeadProjects({ commit }, leadProjects) {
		return new Promise((resolve, reject) => {
			commit('UPDATE_STATUS', false)
			commit('CLEAR_LEAD_PROJECTS')
			commit('ADD_LEAD_PROJECTS', leadProjects)
			commit('UPDATE_STATUS', true)
			resolve()
		})
	},

	invalidateProjects({ commit }) {
		commit('CLEAR_ALL_PROJECTS')
	},

	reset({ commit }) {
		commit('RESET')
	}

}

const mutations = {

	SET_COUNTRIES(state, value) {
		state.countries = value
	},

	SET_ARCADIS_TYPES(state, value) {
		state.arcadisTypes = value
	},

	SET_CLIENT_TYPES(state, value) {
		state.clientTypes = value
	},

	SET_CURRENT_PROJECT(state, value) {
		state.currentProject = value
	},
	UPDATE_CURRENT_PROJECT(state, value) {
		state.currentProject = Object.assign({}, state.currentProject, value)
	},

	ADD_PROJECTS(state, data) {
		state.projects = data
	},

	ADD_LEAD_PROJECTS(state, data) {
		state.leadProjects = data
	},

	CLEAR_ALL_PROJECTS(state) {
		state.projects = []
		state.leadProjects = []
	},

	CLEAR_PROJECTS(state) {
		state.projects = []
	},

	CLEAR_LEAD_PROJECTS(state) {
		state.leadProjects = []
	},

	UPDATE_STATUS(state, status) {
		state.status = status
	},

	RESET(state) {
		const newState = initialState()
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
