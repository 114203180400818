<template>
	<!-- <div class="relative w-full overflow-x-auto rounded-m"> -->
		<table class="min-w-full rounded-md table-auto">
			<thead>
				<tr class="header">
					<th v-for="(header, index) in headers" :key="index">{{ header }}</th>
				</tr>
			</thead>
			<tbody >
				<tr v-for="(data_row, rowindex) in data" :key="rowindex">
					<td class="break-words" v-for="(data_column, colindex) in Object.keys(data[0])" :key="rowindex + '-' + colindex">{{ data_row[data_column] }}</td>
				</tr>
			</tbody>
		</table>
	<!-- </div> -->
</template>

<script>
export default {
	name: 'wm-table',
	props: {
		headers: {
			type: Array,
			default: () => { return [] }
		},
		data: {
			type: Array,
			default: () => {
				return []
			}
		}
	}
}
</script>
<style lang="scss" scoped>
table {
	border-collapse: collapse;
	table-layout: fixed;
	width: 100%;
	margin: 0;
	overflow: hidden;
	font-size: 9.0pt;
	color: #0C0808;

	.header {
		color: #E4610F;
		width: 105pt;
		font-weight: bold;
		border-bottom: solid #E4610F 1px;
		padding: 3px 5px;
		text-align: left;
	}

	tr:last-child td {
		border-bottom: solid #E4610F 1px;
	}
	td {
		border-bottom: solid #0C0808 1px;
	}
	td, th {
		padding: 1.4pt 0cm 1.4pt 0cm;
		min-width: 1em;
		padding: 3px 5px;
		vertical-align: top;
		box-sizing: border-box;
		position: relative;
		> * {
			margin-bottom: 0;
		}
	}

	.selectedCell:after {
		z-index: 2;
		position: absolute;
		content: "";
		left: 0; right: 0; top: 0; bottom: 0;
		background: rgba(200, 200, 255, 0.4);
		pointer-events: none;
	}

	.column-resize-handle {
		position: absolute;
		right: -2px; top: 0; bottom: 0;
		width: 4px;
		z-index: 20;
		background-color: #adf;
		pointer-events: none;
	}
}
</style>
