import { profileService } from '@/services/me'
import Store from '@/store/index'
import Vue from 'vue'

const loginUri = '/login'
const tokenRefreshBeforeMin = 5 // referesh 5 min before expiration
const heartBeatMin = 4 // check token every 4 min

function checkSession(recollectUser = false) {
	console.log(window.location.pathname)
	const token = getAccessToken()
	let shouldGetUser = Store.getters['auth/user'] == null
	if (recollectUser) shouldGetUser = true

	if (!token || !shouldGetUser) {
		return Promise.resolve()
	}
	return getUser()
}

function getUser() {
	return new Promise((resolve, reject) => {
		profileService.show()
			.then(resp => {
				Store.dispatch('Auth/setUser', resp.data).then(resp => {
					resolve()
				})
			})
			.catch(error => {
				reject(error)
			})
	})
}

function getAccessToken(force = false) {
	const accessToken = localStorage.getItem('bwm-token')
	console.log('get/check accestoken', accessToken)

	// if (!force && window.location.pathname === loginUri && !accessToken) {
	// 	return
	// }

	if (accessToken) {
		const jwtMeta = JSON.parse(atob(accessToken.split('.')[1]))
		const refreshBefore = tokenRefreshBeforeMin * 60 * 1000
		if (console && console.log) {
			console.log('check token', jwtMeta.exp * 1000, Date.now(), (jwtMeta.exp * 1000 - Date.now() - refreshBefore) / 60000, 'min')
		}
		if (Date.now() >= jwtMeta.exp * 1000) {
			// token has expired
			if (window.location.pathname !== loginUri) {
				localStorage.removeItem('bwm-token')
				window.location.href = loginUri
			}
		}

		if (force || Date.now() + refreshBefore >= jwtMeta.exp * 1000) {
			const authAxios = window.axios.create({
				headers: {
					common: {
						'Content-Type': 'application/json'
					}
				}
			})
			// token will expire in 5 min
			authAxios.post(process.env.VUE_APP_API_URL + '/refresh_token', { token: accessToken })
				.then((response) => {
					const token = response.data.token
					localStorage.setItem('bwm-token', token)
					console.log('token refreshed')
				})
				.catch((error) => {
					Vue.$toast.error('token refresh failed')
					console.log('token refreshed failed', error)
					if (window.location.pathname !== loginUri) {
						window.location.href = loginUri
					}

					return Promise.resolve(error)
				})
		}
		return accessToken
	}

	// redirect to /account/login
	if (window.location.pathname !== loginUri) {
		window.location.href = loginUri
	}
	return false
}

// heart beat
setInterval(function () {
	getAccessToken()
}, heartBeatMin * 60 * 1000)

export const SessionManager = {
	checkSession,
	getAccessToken,
	getUser
}
