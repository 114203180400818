import Vue from 'vue'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
	faBell,
	faArrowRight,
	faPlus,
	faCog,
	faCopy,
	faEye,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faEnvelopeOpenText,
	faTimes,
	faPencil,
	faUser,
	faTrashAlt,
	faSpinner,
	faBold,
	faItalic,
	faStrikethrough,
	faUnderline,
	faListUl,
	faListOl,
	faTable,
	faMinusCircle,
	faMobile,
	faColumns,
	faMinusSquare,
	faArrowToLeft,
	faArrowToRight,
	faArrowToBottom,
	faArrowToTop,
	faArrowsAltH,
	faCompressAlt,
	faShieldCheck,
	faExclamationTriangle,
	faCheck,
	faSync,
	faUserPlus,
	faSave,
	faDownload,
	faExclamation,
	faFile,
} from '@fortawesome/pro-regular-svg-icons'

import {
	faGripVertical as fasGripVertical,
	faCaretRight as fasCaretRight,
	faUserPlus as fasUserPlus
} from '@fortawesome/pro-solid-svg-icons'

import {
	faFileAlt,
	faRoute as falRoute
} from '@fortawesome/pro-light-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon)

library.add(
	faBell,
	faArrowRight,
	faPlus,
	faCopy,
	faEye,
	faCog,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	fasCaretRight,
	fasUserPlus,
	faEnvelopeOpenText,
	faTimes,
	faPencil,
	faExclamation,
	faUser,
	faTrashAlt,
	faSpinner,
	faBold,
	faItalic,
	faStrikethrough,
	faUnderline,
	faListUl,
	faListOl,
	faTable,
	faMinusCircle,
	faMobile,
	faColumns,
	faArrowToLeft,
	faArrowToRight,
	faArrowToBottom,
	faArrowToTop,
	faMinusSquare,
	faArrowsAltH,
	faCompressAlt,
	faShieldCheck,
	faCheck,
	faSync,
	faDownload,
	faExclamationTriangle,
	faSave,
	fasGripVertical,
	falRoute,
	faFileAlt
)

dom.watch() // This will kick of the initial replacement of i to svg tags and configure a MutationObserver
