import '@/bootstrap'
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import Toast from 'vue-toastification'
import Alledaags from '@/plugins/alledaags'
import i18n from '@/i18n'
import 'vue-toastification/dist/index.css'
import '@/helpers/directives'
import '@/helpers/filters'
import '@/helpers/fontawesome'
import '@/helpers/interceptors'
import '@/helpers/mixin'
import { SessionManager } from '@/helpers/sessionmanager'
import vueNumeralFilterInstaller from 'vue-numeral-filter'
import numeral from 'numeral'
import VCalendar from 'v-calendar'

import '@/assets/css/tailwind.scss'

Vue.config.productionTip = false

const toastOptions = {
	position: 'bottom-right',
	timeout: 3000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: true,
	closeButton: 'button',
	icon: false,
	rtl: false,
	maxToasts: 20,
	newestOnTop: false,
	toastClassName: 'arcadis-toast'
}

Vue.use(Toast, toastOptions)
Vue.use(Alledaags)
Vue.use(vueNumeralFilterInstaller, { locale: 'nl-nl' })
Vue.use(VCalendar, {
	datePicker: {
		color: 'orange',
		popover: { visibility: 'focus', keepVisibleOnInput: true }
	}
})

Vue.component('form-toggle-switch', require('@/components/form/ToggleSwitch.vue').default)
Vue.component('form-button-switch', require('@/components/form/ButtonSwitch.vue').default)
Vue.component('form-check-box', require('@/components/form/Checkbox.vue').default)
Vue.component('dropdown-search', require('@/components/form/DropdownSearch.vue').default)
Vue.component('collapse-item', require('@/components/CollapseItem.vue').default)
Vue.component('wm-table', require('@/components/Table.vue').default)
Vue.component('wysiwyg-area', require('@/components/form/WysiwygArea.vue').default)
Vue.component('auto-resize', require('@/components/AutoResize.vue').default)

//button
Vue.component('add-item-button', require('@/components/buttons/AddItemButton.vue').default)

function initializeApp() {
	new Vue({
		router,
		store,
		i18n,
		render: (h) => h(App)
	}).$mount('#app')
}

numeral.register('locale', 'nl-custom', {
	delimiters: {
		thousands: '.',
		decimal: ','
	},
	abbreviations: {
		thousand: 'k',
		million: 'mln',
		billion: 'mrd',
		trillion: 'bln'
	},
	ordinal: function (number) {
		var remainder = number % 100
		return ((number !== 0 && remainder <= 1) || remainder === 8 || remainder >= 20) ? 'ste' : 'de'
	},
	currency: {
		symbol: '€'
	}
})

// switch between locales
numeral.locale('nl-custom')

SessionManager.checkSession().then(resp => {
	initializeApp()
}).catch(error => {
	if (error) {
		console.log(error)
	}
	initializeApp()
})
