<template>
	<div class="flex items-center">
		<button class="flex items-center justify-center p-1.5 h-6 w-6 rounded-full btn btn-orange mr-2 cursor-pointer" @click="$emit('click')">
			<font-awesome-icon :icon="['far', 'plus']" />
		</button>
		<span class="flex items-center text-base text-arcadis-orange-500 cursor-pointer" @click="$emit('click')">{{label}}</span>
	</div>
</template>
<script>
export default {
	name: 'AddItemButton',
	props: {
		label: { default: 'add item' }
	}
}
</script>