<template>
	<transition
			enter-active-class="transition duration-100 ease-out"
			enter-class="opacity-0"
			enter-to-class="opacity-100"
			leave-active-class="transition duration-75 ease-in"
			leave-class="opacity-100"
			leave-to-class="opacity-0"
			mode="out-in"
			@after-enter="showDialog = true">

	<div v-if="isActive"  v-trap-focus="true" class='fixed inset-0 z-50 overflow-y-auto'>
		<div class='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
			<div class='fixed inset-0 transition-opacity'>
				<div class='absolute inset-0 opacity-75 bg-arcadis-gray-800'></div>
			</div>

			<!-- This element is to trick the browser into centering the modal contents. -->
			<span class='hidden sm:inline-block sm:align-middle sm:h-screen'></span>&#8203;

		<transition
			enter-active-class="transition duration-300 ease-out"
			enter-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
			enter-to-class="translate-y-0 opacity-100 sm:scale-100"
			leave-active-class="transition duration-100 ease-in"
			leave-class="translate-y-0 opacity-100 sm:scale-100"
			leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
			@enter="focusDialog"
			@after-leave="isActive = false"
			mode="out-in">
			<div v-show="showDialog" class='inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full' role='dialog' aria-modal='true' aria-labelledby='modal-headline'>
				<div class='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
					<div class='sm:flex sm:items-start'>
						<div class='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
							<h3 v-if="title" class='text-2xl font-semibold leading-tight' id='modal-headline'>
								{{ title }}
							</h3>

							<div class='mt-2'>
								<p class='text-base leading-5'>
									<!-- Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone. -->
									<template v-if="$slots.default">
										<slot />
									</template>
									<template v-else>
										<div v-html="message" />
									</template>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class='px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse'>
					<span  class='flex w-full rounded-md sm:ml-3 sm:w-auto'>
						<button @click="confirm" type='button' class='inline-flex justify-center w-full focus:outline-none btn btn-orange' ref="confirmButton">
							{{ confirmText }}
						</button>
					</span>
					<span class='flex w-full mt-3 rounded-md sm:mt-0 sm:w-auto'>
						<button v-if="showCancel" @click="cancel('button')" type='button' class='inline-flex justify-center w-full focus:outline-none btn btn-white' ref="cancelButton">
							{{ cancelText }}
						</button>
					</span>
				</div>
			</div>
	</transition>
		</div>
	</div>
	</transition>
</template>

<script>
/* eslint-disable */
import trapFocus from '../../directives/trapFocus'
import config from '../../utils/config'
import { removeElement } from '../../utils/helpers'
export default {
	name: 'ADialog',
	directives: {
		trapFocus
	},
	props: {
		title: String,
		message: [String, Array],
		confirmText: {
			type: String,
			default: () => {
				return config.defaultDialogConfirmText
					? config.defaultDialogConfirmText
					: 'OK'
			}
		},
		cancelText: {
			type: String,
			default: () => {
				return config.defaultDialogCancelText
					? config.defaultDialogCancelText
					: 'Cancel'
			}
		},
		canCancel: {
			type: [Array, Boolean],
			default: () => {
				return config.defaultModalCanCancel
			}
		},
		onCancel: {
			type: Function,
			default: () => {}
		},
		onConfirm: {
			type: Function,
			default: () => {}
		},
		closeOnConfirm: {
			type: Boolean,
			default: true
		},
		container: {
			type: String,
			default: () => {
				return config.defaultContainerElement
			}
		},
		focusOn: {
			type: String,
			default: 'confirm'
		},
		trapFocus: {
			type: Boolean,
			default: () => {
				return config.defaultTrapFocus
			}
		},
		autoFocus: {
			type: Boolean,
			default: () => {
				return config.defaultAutoFocus
			}
		},
		ariaRole: {
			type: String,
			validator: (value) => {
				return ['dialog', 'alertdialog'].indexOf(value) >= 0
			}
		},
		ariaModal: Boolean
	},
	data () {
		const prompt = this.hasInput ? this.inputAttrs.value || '' : ''
		return {
			prompt,
			isActive: false,
			validationMessage: '',
			showDialog: false
		}
	},
	computed: {
		dialogClass () {
			return [
				this.size,
				{
					'has-custom-container': this.container !== null
				}
			]
		},
		showCancel () {
			return this.cancelOptions.indexOf('button') >= 0
		},
		cancelOptions () {
			return typeof this.canCancel === 'boolean' ? this.canCancel ? config.defaultModalCanCancel : [] : this.canCancel
		}
	},
	watch: {
		active (value) {
			this.isActive = value
		},
		isActive (value) {
			if (value) this.destroyed = false
			this.handleScroll()
			this.$nextTick(() => {
				if (value && this.$el && this.$el.focus && this.autoFocus) {
					this.$el.focus()
				}
			})
		}
	},
	created () {
		if (typeof window !== 'undefined') {
			document.addEventListener('keyup', this.keyPress)
		}
	},
	beforeMount () {
		if (typeof window !== 'undefined') {
			this.$nextTick(() => {
				const container = document.querySelector(this.container) || document.body
				container.appendChild(this.$el)
			})
		}
	},
	mounted () {
		this.isActive = true
		if (this.isActive) this.handleScroll()
	},
	beforeDestroy () {
		if (typeof window !== 'undefined') {
			document.removeEventListener('keyup', this.keyPress)
			// reset scroll
			document.documentElement.classList.remove('is-clipped')
			const savedScrollTop = !this.savedScrollTop
				? document.documentElement.scrollTop
				: this.savedScrollTop
			document.body.classList.remove('is-noscroll')
			document.documentElement.scrollTop = savedScrollTop
			document.body.style.top = null
		}
	},
	methods: {
		focusDialog () {
			this.$nextTick(() => {
				// Handle which element receives focus
				if (this.focusOn === 'cancel' && this.showCancel) {
					this.$refs.cancelButton.focus()
				} else {
					this.$refs.confirmButton.focus()
				}
			})
		},
		confirm () {
			if (this.$refs.input !== undefined) {
				if (!this.$refs.input.checkValidity()) {
					this.validationMessage = this.$refs.input.validationMessage
					this.$nextTick(() => this.$refs.input.select())
					return
				}
			}
			this.$emit('confirm', this.prompt)
			this.onConfirm(this.prompt, this)
			if (this.closeOnConfirm) this.close()
		},
		cancel (method) {
			if (this.cancelOptions.indexOf(method) < 0) return
			this.$emit('cancel', arguments)
			this.onCancel.apply(null, arguments)
			this.close()
		},
		close () {
			this.showDialog = false
			// Timeout for the animation complete before destroying
			setTimeout(() => {
				this.$destroy()
				removeElement(this.$el)
			}, 175)
		},
		keyPress ({ key }) {
			if (this.isActive && (key === 'Escape' || key === 'Esc')) this.cancel('escape')
		},
		handleScroll () {
			if (typeof window === 'undefined') return
			if (this.scroll === 'clip') {
				if (this.isActive) {
					document.documentElement.classList.add('is-clipped')
				} else {
					document.documentElement.classList.remove('is-clipped')
				}
				return
			}
			this.savedScrollTop = !this.savedScrollTop
				? document.documentElement.scrollTop
				: this.savedScrollTop
			if (this.isActive) {
				document.body.classList.add('is-noscroll')
			} else {
				document.body.classList.remove('is-noscroll')
			}
			if (this.isActive) {
				document.body.style.top = `-${this.savedScrollTop}px`
				return
			}
			document.documentElement.scrollTop = this.savedScrollTop
			document.body.style.top = null
			this.savedScrollTop = null
		}
	}
}
</script>
