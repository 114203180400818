import Vue from 'vue'
import moment from 'moment'

Vue.filter('excerpt', (value, num = 18) => {
	if (value.length > num) {
		return value.slice(0, num) + '...'
	}
	return value
})

Vue.filter('whenEmpty', (value, show = '-') => {
	if (!value || value.length === 0) {
		return show
	}
	return value
})

Vue.filter('whenEmptyNumber', (value, show = 0) => {
	if (!value || value.length === 0) {
		return show
	}
	return value
})

Vue.filter('formatDateCalender', value => {
	if (value) {
		moment.locale('en', {
			longDateFormat: {
				LT: 'h:mm:ss A'
			}
		})
		return moment.utc(String(value)).local().calendar(null, { sameElse: 'L LT' })
	}
})

Vue.filter('formatDateDMY', value => {
	if (value) {
		return moment.utc(String(value)).local().format('DD-MM-yyyy')
	}
})
