import Vue from 'vue'
import VueRouter from 'vue-router'
import { SessionManager } from '@/helpers/sessionmanager'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'dashboard.index',
		meta: {
			requiresAuth: []
		},
		component: () => import(/* webpackChunkName: "dashboard-index" */ '@/views/pages/dashboard/Index.vue')
	},
	{
		path: '/login',
		name: 'login.index',
		meta: {
			layout: 'no-user'
		},
		component: () => import(/* webpackChunkName: "account-login" */ '@/views/pages/account/Login.vue')
		// uitgeklede layout
	},
	{
		path: '/projects',
		name: 'projects.index',
		meta: {
			requiresAuth: []
		},
		component: () => import(/* webpackChunkName: "projects-index" */'@/views/pages/projects/Index.vue')
	},
	{
		path: '/projects/create',
		name: 'projects.create',
		meta: {
			requiresAuth: []
		},
		component: () => import(/* webpackChunkName: "projects-create" */'@/views/pages/projects/Create.vue')
	},
	{
		path: '/projects/:projectId',
		meta: {
			requiresAuth: []
		},
		component: () => import(/* webpackChunkName: "projects-show" */'@/views/pages/projects/Show.vue'),
		children: [
			{
				path: '',
				name: 'projects.show',
				meta: {
					requiresAuth: []
				},
				component: () => import(/* webpackChunkName: "projects-show-info" */'@/views/pages/projects/info/Show.vue')
			},
			{
				path: 'subprojects',
				name: 'projects.show.subprojects',
				meta: {
					requiresAuth: []
				},
				component: () => import(/* webpackChunkName: "projects-show-subprojects" */'@/views/pages/projects/subprojects/Index.vue')
			},
			{
				path: 'subprojects/:subprojectId',
				name: 'subprojects.show',
				meta: {
					requiresAuth: []
				},
				component: () => import(/* webpackChunkName: "projects-show-subprojects" */'@/views/pages/projects/subprojects/Index.vue')
			},
			{
				path: 'team',
				name: 'projects.show.team',
				meta: {
					requiresAuth: []
				},
				component: () => import(/* webpackChunkName: "projects-show-team" */'@/views/pages/projects/team/Show.vue')
			}
		]
	},
	{
		path: '/account/profile',
		name: 'account.profile.show',
		meta: {
			requiresAuth: []
		},
		component: () => import(/* webpackChunkName: "account-profile-show" */'@/views/pages/account/profile/Show.vue')
	},
	{
		path: '/users',
		name: 'users.index',
		meta: {
			requiresAuth: []
		},
		component: () => import('@/views/pages/users/Index.vue')
	},
	{
		path: '/notifications',
		name: 'notifications.index',
		component: () => import('@/views/pages/notifications/Index.vue')
	},
	{
		path: '/workflow-builder',
		redirect: { name: 'workflow_builder.project_templates' },
		meta: {
			requiresAuth: []
		},
		component: () => import('@/views/pages/workflowBuilder/Index.vue'),
		children: [
			{
				path: 'index',
				name: 'workflow_builder.project_templates',
				meta: {
					requiresAuth: []
				},
				component: () => import('@/views/pages/workflowBuilder/projectTemplates/Index.vue')
			},
			{
				path: 'create',
				name: 'workflow_builder.project_templates.create',
				meta: {
					requiresAuth: []
				},
				component: () => import('@/views/pages/workflowBuilder/projectTemplates/Create.vue')
			},
			{
				path: 'tasks',
				name: 'workflow_builder.tasks',
				meta: {
					requiresAuth: []
				},
				component: () => import('@/views/pages/workflowBuilder/tasks/Index.vue')
			},
			{
				path: ':templateId',
				name: 'workflow_builder.project_templates.show',
				meta: {
					requiresAuth: []
				},
				component: () => import('@/views/pages/workflowBuilder/projectTemplates/Show.vue')
			},
			{
				path: 'tasks/create',
				name: 'workflow_builder.tasks.create',
				meta: {
					requiresAuth: []
				},
				component: () => import('@/views/pages/workflowBuilder/tasks/Create.vue')
			},
			{
				path: 'tasks/:taskId',
				name: 'workflow_builder.tasks.show',
				meta: {
					requiresAuth: []
				},
				component: () => import('@/views/pages/workflowBuilder/tasks/Show.vue')
			}
		]
	},

	{
		path: '/alledaags/styles',
		name: 'alledaags.styles',
		component: () => import(/* webpackChunkName: "alledaags-styles" */'@/views/pages/_alledaags/Styles.vue')
	},
	{
		path: '/alledaags/components',
		name: 'alledaags.components',
		component: () => import(/* webpackChunkName: "alledaags-styles" */'@/views/pages/_alledaags/Components.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeResolve((to, from, next) => {
	const { requiresAuth } = to.meta;
	if (requiresAuth) {
		let hasToken = SessionManager.getAccessToken()
		if (hasToken) {
			return next();
		} else {
			return next({ path: '/login' });
		}

	}else{
		return next();
	}
});

export default router
